import React from "react"
import {
  FaGithub,
  FaLinkedin,
  FaTwitterSquare,
  FaDev,
  FaMedium,
} from "react-icons/fa"

import { MdEmail } from "react-icons/md"

const data = [
  {
    id: 1,
    icon: <FaGithub className="social-icon"></FaGithub>,
    url: "https://github.com/shashankkatte",
  },
  {
    id: 2,
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: "https://www.linkedin.com/in/shashankkatte/",
  },

  {
    id: 3,
    icon: <FaDev className="social-icon"></FaDev>,
    url: "https://dev.to/shashankkatte",
  },
  {
    id: 4,
    icon: <FaMedium className="social-icon"></FaMedium>,
    url: "https://shashankkatte.medium.com/",
  },
  {
    id: 5,
    icon: <FaTwitterSquare className="social-icon"></FaTwitterSquare>,
    url: "https://twitter.com/shashankkatte",
  },
  {
    id: 6,
    icon: <MdEmail className="social-icon"></MdEmail>,
    url: "mailto:shashank@katte.io",
  },
]
const links = data.map(link => {
  return (
    <li key={link.id}>
      <a href={link.url} className="social-link">
        {link.icon}
      </a>
    </li>
  )
})

export default ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  )
}
